import { useState, useEffect, useMemo, useCallback } from "react";

import { isArray, isNonEmptyArray } from "_helpers";
import { getReportingExtraGrid, getReporting } from "_services";

import dayjs from 'dayjs';

export function useReportingGridGroups( initialColumDefs ){
    const [ columnDefs, setColumnDefs] = useState([]);
    const [ fields, setFields ] = useState([]);
    const [ doRedirect, setDoRedirect ] = useState(false);

    useEffect(() => {
        if( isNonEmptyArray(initialColumDefs) ){
            try {
                const timer = setTimeout(async () => {
                    const { response, data, redirect } = await getReportingExtraGrid();

                    if( response ){
                        const fetchGrid = data.config;
                        const totalObj = [{
                            headerName: "", groupId: "end", children: [
                                { headerName: "Solde", field: "amount_reste", type: "valueColumn" },
                            ]
                        }];
                        
                        setColumnDefs([...initialColumDefs, ...fetchGrid, ...totalObj]);
                        
                        const fetchFields = data.fields;
                        setFields(fetchFields);
                    } else {
                        setColumnDefs([]);
                        setFields([]);
                    }

                    setDoRedirect(redirect);
                }, 0)
    
                return () => clearTimeout(timer);
            } catch(e){
                setColumnDefs([]);
                setFields([]);
                setDoRedirect(false);
                throw new Error(e);
            }
        }
    }, [initialColumDefs])

    return {
        fetchColumnDefs: columnDefs,
        fetchTableFields: fields,
        reportingGridGroupsDoRedirect: doRedirect
    };
}

export function useReporting( fields, payload ){
    const [ reportingData, setReportingData ] = useState([]);
    const [ reportingToBeChecked, setReportingToBeChecked ] = useState({});
    const [ doRedirect, setDoRedirect ] = useState(false);

    const formatEN = useMemo(() => "YYYY-MM-DD", []);

    const initialBillingDataState = useMemo(() => ({
        'LOCAL': [],
        'REMOTE': [],
        'BOTH' : []
    }), []);

    const [ billingData, setBillingData ] = useState(initialBillingDataState);

    const [ bases, setBases ] = useState([]);
    const [ entities, setEntities ] = useState([]);
    const [ constructDates, setConstructDates ] = useState({
        scopeDateStart: null,
        scopeDateEnd: null
    });
    const [ displayDates, setDisplayDates ] = useState({
        calcDateStart: null,
        calcDateEnd: null
    });

    const { formIsSubmitted } = payload;

    const values = useMemo(() => (
        {
            'bases': bases,
            'entities' : entities,
            'scopeDateStart': constructDates.scopeDateStart,
            'scopeDateEnd': constructDates.scopeDateEnd,
            'calcDateStart': displayDates.calcDateStart,
            'calcDateEnd': displayDates.calcDateEnd
        }
    ),[bases, entities, constructDates, displayDates]);

    const resetAllFetchData = useCallback(() => {
        setReportingData([]);
        setBillingData(initialBillingDataState);
        setReportingToBeChecked({});
        setDoRedirect(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setBases(fields.bases);
        setEntities(fields.entities);

        const [ scopeDateStart, scopeDateEnd ] = fields.constructDates;

        let constructDatesTemp = {
            scopeDateStart: scopeDateStart ? dayjs(scopeDateStart).format(formatEN) : null, 
            scopeDateEnd: scopeDateEnd ? dayjs(scopeDateEnd).format(formatEN) : null
        };

        setConstructDates(constructDates => ({...constructDates, ...constructDatesTemp}));

        const [ calcDateStart, calcDateEnd ] = fields.displayDates;

        let displayDatesTemp = {
            calcDateStart: calcDateStart ? dayjs(calcDateStart).format(formatEN) : null, 
            calcDateEnd: calcDateEnd ? dayjs(calcDateEnd).format(formatEN) : null
        };

        setDisplayDates(displayDates => ({...displayDates, ...displayDatesTemp}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields])

    useEffect(() => {
        const entries = Object.entries(values).filter(([key]) => !['scopeDateStart', 'scopeDateEnd'].includes(key))
        const newValues = Object.fromEntries(entries);
   
        const valuesAreReady = Object.values(newValues).every(newValue => isNonEmptyArray(newValue) || (!isArray(newValue) && newValue));

        if( !valuesAreReady ){
            resetAllFetchData();
        } else if( valuesAreReady && formIsSubmitted ){
            try {
                const timer = setTimeout(async () => {
                    const { response, data, redirect } = await getReporting(values);

                    if( response ){
                        const reporting = data.reporting;
                        const billing = data.billing;

                        setReportingData(reporting.records);
                        setReportingToBeChecked(reporting.to_be_checked);
                        setBillingData(billing.records);
                    } else {
                        resetAllFetchData();
                    }

                    setDoRedirect(redirect);
                }, 0)
    
                return () => clearTimeout(timer);
            } catch(e){
                resetAllFetchData();
                throw new Error(e);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, formIsSubmitted])

    return {
        fetchReportingData: reportingData,
        fetchReportingToBeChecked: reportingToBeChecked,
        fetchBillingData: billingData,
        initialBillingDataState,
        resetAllFetchData,
        reportingDoRedirect: doRedirect
    }
}