import axios from "axios";

const instance = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? "" : process.env.REACT_APP_API_URL,
    // timeout: 1000,
    // headers: {'X-Custom-Header': 'foobar'}
});

export function createGetRequest(url) {
  return(
    instance({
      url: url,
      method: "GET",
      timeout: 60000,
      withCredentials: true,
      headers: {
        "Content-Type" : "application/json",
      }
    })
  );
};

export function createPostRequest(url, data) {
  return(
    instance({
        url: url,
        method: "POST",
        timeout: 100000,
        withCredentials: true,
        headers: {
          "Content-Type" : "application/json",
        },
        data: JSON.stringify(data)
      })
  ); 
};

export function createFileDownloadRequest(url, data) {
  return(
    instance({
      url: url,
      method: "POST",
      withCredentials: true,
      responseType: 'blob',
      data: JSON.stringify(data)
    })
  );
}