import { createGetRequest, createPostRequest } from '_helpers';

export async function getReportingExtraGrid( ) {
    return createGetRequest("reporting_getExtraGrid")
        .then(res => res.data)
        .then(({ success, data, errorMsg, redirect = false }) => {
            return { response: success, data, errorMsg, redirect };
        })
}

export async function getReporting( values ) {
    return createPostRequest("reporting_getData", values)
        .then(res => res.data)
        .then(({ success, data, errorMsg, redirect = false }) => {
            return { response: success, data, errorMsg, redirect };
        })
}