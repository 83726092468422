// Objects
export function isObject(element){
    return element &&
        !Array.isArray(element) &&
        typeof element === "object";
}

export function isEmptyObject(element){
    return isObject(element) && 
        Object.keys(element).length === 0;
}

export function isNonEmptyObject(element){
    return isObject(element) &&
        Object.keys(element).length > 0;
}

export function objectsAreEquals(obj1, obj2){
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function objectsAreUnequals(obj1, obj2){
    return JSON.stringify(obj1) !== JSON.stringify(obj2);
}

export function shallowCopy( el ){
    if( isObject(el) ) return {...el};
    if( isArray(el) ) return [...el];
    return undefined;
}

export function deepCopy( el ){
    // Tip : For better result use _lodash library
    return JSON.parse(JSON.stringify(el));
}

// Arrays
export function isArray(element){
    return element && 
        Array.isArray(element);
}

export function isEmptyArray(element){
    return isArray(element) &&
        element.length === 0;
}

export function isUniqueValueArray(element){
    return isArray(element) &&
        element.length === 1;
}

export function isNonEmptyArray(element){
    return isArray(element) && 
        element.length > 0;
}

export function arraysAreEquals(arr1, arr2){
    if( isArray(arr1) && isArray(arr2) ){
        return arr1.length === arr2.length &&
            arr1.every(a => arr2.indexOf(a) !== -1) ;
    }
    return false;
}

export function arraysAreUnequals(arr1, arr2){
    if( isArray(arr1) && isArray(arr2) ){
        return arr1.length !== arr2.length ||
            arr1.every(a => arr2.indexOf(a) === -1) ;
    }
    return false;
}

// Both
export function isEmpty(el){
    return isEmptyArray(el) ||
        isEmptyObject(el);
}

export function isNonEmpty(el){
    return isNonEmptyArray(el) ||
        isNonEmptyObject(el);
}

// Types
export function isBool(val){
    return typeof val === "boolean" ||
        typeof !!Number(val) === "boolean";
}

export function isStr(val){
    return typeof val === "string";
}

// Others
export function hasPermission(permissionIds, user) {
    return permissionIds.every(pI => (user?.permissions || []).includes(pI)) || user?.isAdmin;
}