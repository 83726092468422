// import LogoEndPage from '_assets/reporting/pdf_report/logo_recouveo_endPage.png';
import Logo from '_assets/reporting/pdf_report/logo_recouveo.png';
import Portrait from '_assets/reporting/pdf_report/portrait_001.png';
import PageHeader from '_assets/reporting/pdf_report/template_header_page.png';
import PageLeftSection from '_assets/reporting/pdf_report/template_left_section_page.png';

import * as htmlToImage from 'html-to-image';
// eslint-disable-next-line no-unused-vars
import autoTable from 'jspdf-autotable';

export async function buildPdf({ doc, elements, data, tableConfig, translate }){
    // let top = 20;
    // const padding = 10;

    const { clientNameToExport, reportingAtToPdf } = data;
    const { columns, body, footer } = tableConfig;

    // First Page
    doc.addImage(Logo, "PNG", 25, 15, 80, 60);

    const rightPartXPosition = 130;
    
    doc.setFontSize(24).setFont(undefined, 'bold');
    let xOffset;
    if( clientNameToExport ){
        xOffset = ( rightPartXPosition - doc.getTextWidth(clientNameToExport) ) / 2; 
        doc.text(clientNameToExport, xOffset, 140);
    }
    doc.setTextColor('#0b6fba');
    xOffset = ( rightPartXPosition - doc.getTextWidth(translate("PdfExport.firstPage")) ) / 2; 
    doc.text(translate("PdfExport.firstPage"), xOffset, 160);
    xOffset = ( rightPartXPosition - doc.getTextWidth(reportingAtToPdf) ) / 2;
    doc.text(reportingAtToPdf, xOffset, 170);

    // landmark
    // doc.setLineDashPattern([10,10], 0).line(64.96875, 210, 64.96875,  0);

    doc.addImage(Portrait, "PNG", rightPartXPosition, 0, 167.0625, 210);
    
    // doc.setLineDashPattern([10,10], 0).line(148.5, 210, 148.5,  0);

    let pageNumber = 1;

    // Second Page
    for (let i = 0; i < elements.length; i++) {
        doc.addPage();

        pageNumber++;

        doc.addImage(PageHeader, "PNG", -1.5, -1, 300, 0);
        doc.addImage(PageLeftSection, "PNG", 5, 65, 15, 70);

        doc.setTextColor('#d9d9d9').setFontSize(28).setFont(undefined, 'bold');
        doc.text(pageNumber.toString(), 9, 73);

        const texts = elements[i]['texts'];

        doc.setFontSize(16);

        let prevY = null;

        texts.forEach(el => {
            const { txt, fontWeight, color, positions, maxWidth } = el,
                    { x, y } = positions;

            if( !txt ){
                prevY = y;
                return;
            } 

            let defaultColor = '#808080',
                defaultFontWeight = 'normal';

            if( color ) defaultColor = color;
            if( fontWeight ) defaultFontWeight = fontWeight;

            doc.setTextColor(defaultColor).setFont(undefined, defaultFontWeight);
            if( prevY ){
                doc.text(txt, x, prevY, { maxWidth: maxWidth ? maxWidth : 0 });
                prevY = null;
            } else {
                doc.text(txt, x, y, { maxWidth: maxWidth ? maxWidth : 0 });
            }
        })

        const el = elements[i]['item'];
        const imgData = await htmlToImage.toPng(el);
    
        let elHeight = el.offsetHeight * 0.2645833333; // px to mm
        let elWidth = el.offsetWidth * 0.2645833333;

        const scale = window.devicePixelRatio;
        
        const height = elHeight * scale;
        const width = elWidth * scale;

        const pageHeight = doc.internal.pageSize.getHeight();

        let yOffset = ( pageHeight - height ) / 2; 

        // const pageWidth = doc.internal.pageSize.getWidth() - 67;
    
        // if (elWidth > pageWidth) {
        // const ratio = pageWidth / elWidth;
        // elHeight = elHeight * ratio - padding;
        // elWidth = elWidth * ratio - padding;
        // }
    
        // const pageHeight = doc.internal.pageSize.getHeight() - 46;
    
        // if (top + elHeight > pageHeight) {
        //     // doc.addPage();
        //     top = 20;
        // }

        // Each ChartImg
        doc.addImage(imgData, "PNG", 50, yOffset, width, height, `image${i}`);
        // top += elHeight;
    }

    // End Page
    doc.addPage();

    pageNumber++;

    doc.addImage(PageHeader, "PNG", -1.5, -1, 300, 0);
    doc.addImage(PageLeftSection, "PNG", 5, 65, 15, 70);

    doc.setTextColor('#d9d9d9').setFontSize(28).setFont(undefined, 'bold');
    doc.text(pageNumber.toString(), 9, 73);

    doc.setTextColor('#808080').setFontSize(16);
    doc.text(translate("PdfExport.fifthPageHeader"), 50, 32);

    const commonTableStyle = {
        lineWidth: 0.2,
        lineColor: [0,0,0],
        halign: 'center',
        valign: 'middle'
    }

    doc.autoTable({
        columns: columns,
        headStyles: {
            fillColor: '#0b6fba',
            ...commonTableStyle
        },
        body: body,
        bodyStyles: {
            ...commonTableStyle
        },
        foot: footer,
        footStyles: {
            fillColor: '#0b6fba',
            ...commonTableStyle
        },
        startY: 50,
        margin: {
            left: 50,
            right: 27
        }
    })

    // doc.addPage();

    // doc.addImage(LogoEndPage, "PNG", 12, 15, 100, 60);

    // doc.setTextColor('#808080').setFontSize(24).setFont(undefined, 'bold');
    // doc.text(('Vos contacts :').toUpperCase(), 28, 120);

    // contacts.forEach(el => {
    //     const { txt, fontWeight, fontSize, positions } = el,
    //                 { x, y } = positions;

    //     let defaultSize = 16,
    //         defaultFontWeight = 'normal';

    //     if( fontSize ) defaultSize = fontSize;
    //     if( fontWeight ) defaultFontWeight = fontWeight;

    //     doc.setFontSize(defaultSize).setFont(undefined, defaultFontWeight);
    //     doc.text(txt, x, y);
    // })

    // doc.addImage(Portrait, "PNG", 130, 0, 167.0625, 210);

    // doc.setLineDashPattern([10,10], 0).line(64.96875, 210, 64.96875,  0);
}